#breadcrumb {
    background-color: rgba(0, 47, 255, 0.624);
    text-align: center;
    width: 100%;
    display: flex;
}
.breadcrumbs {
    margin-top: 73px;
    text-align: center;
    padding: 30px 0;
    color: #fff;
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 63px;
    }
}

.breadcrumbs h2 {
    font-size: 32px;
    font-weight: 500;
}

.breadcrumbs i {
    font-size: 14px;
    margin-bottom: 0;
}