@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap");

h1,
h2,
h3 {
  font-family: "Manrope", sans-serif;
}

h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

p {
  font-family: "Ubuntu Sans", sans-serif;
  padding: 0
}

* {
  box-sizing: border-box;
}